<template>
  <div :class="$style.contacts">
    <div :class="$style.wrapper">
      <a :href="$configData.addwine_link + slug" target="_blank">
        <el-button size="small" type="primary">Просмотреть</el-button>
      </a>
      <el-button
        size="small"
        type="primary"
        :class="$style.button"
        @click="submitForm()"
        >Сохранить контент</el-button
      >
    </div>
    <el-form
      :model="form"
      :rules="rules"
      ref="form"
      label-position="right"
      label-width="12.5rem"
      :class="$style.form"
    >
      <h2 :class="$style.name">Страница: Документы</h2>
      <div :class="$style.orderInfo">
        <el-form-item label="Заголовок" prop="title">
          <el-input v-model="form.title" />
        </el-form-item>

        <el-form-item label="SEO-тексты">
          <SeoTextsBlock
            v-if="form.seoTexts"
            :seoTexts="form.seoTexts"
            @getSeo="getSeoTexts"
          />
        </el-form-item>
      </div>

      <el-form-item label="SEO-FAQ">
        <SeoFaqsBlock
          v-if="form.seoFaqs"
          :seoFAQs="form.seoFaqs"
          @getSeo="getSeoFaqs"
        />
      </el-form-item>

      <SeoBlock
        :title.sync="form.seo.title"
        :description.sync="form.seo.description"
        :og-title.sync="form.seo.ogTitle"
        :og-description.sync="form.seo.ogDescription"
        :og-image.sync="form.seo.ogImage"
        :class="$style.seo"
      />
    </el-form>
  </div>
</template>

<script>
import delivery from '@/delivery'
import SeoBlock from '@/components/moleculs/SEO.vue'
import SeoTextsBlock from '@/components/organisms/SeoTextsBlock.vue'
import SeoFaqsBlock from '@/components/organisms/SeoFaqsBlock.vue'
import notifications from '@/mixins/notifications'

export default {
  components: {
    SeoBlock,
    SeoTextsBlock,
    SeoFaqsBlock,
  },
  mixins: [notifications],
  data() {
    return {
      slug: '',
      form: {
        title: '',
        seoTexts: [],
        seoFaqs: [],
        seo: {
          title: '',
          description: '',
          ogTitle: '',
          ogDescription: '',
          ogImage: '',
        },
      },
      rules: {
        title: [
          {
            required: true,
            message: 'Пожалуйста, заполните поле',
            trigger: 'blur',
          },
        ],
      },
    }
  },

  async created() {
    await this.getContent()
  },

  methods: {
    getSeoTexts(seoTexts) {
      this.form.seoTexts = seoTexts
    },
    getSeoFaqs(seoFaqs) {
      this.form.seoFaqs = seoFaqs
    },
    async getContent() {
      const loading = this.$loading({
        lock: true,
      })

      const { value, error } =
        await delivery.ContentServiceCore.PagesActions.get(
          'documents',
          'addwine',
        )
      loading.close()

      if (error) return
      this.slug = value.slug
      this.form = value.content

      if (this.form.seoTexts && this.form.seoTexts.length)
        this.form.seoTexts = this.form.seoTexts.map((seoText) => seoText.id)
      else this.form.seoTexts = []

      if (this.form.seoFaqs && this.form.seoFaqs.length)
        this.form.seoFaqs = this.form.seoFaqs.map((seoFaq) => seoFaq.id)
      else this.form.seoFaqs = []
    },
    async submitForm() {
      this.$refs['form'].validate(async (valid) => {
        if (valid) {
          const loading = this.$loading({
            lock: true,
          })

          const seoTexts = this.form.seoTexts?.map((seoText) => ({
            id: seoText,
          }))

          const seoFaqs = this.form.seoFaqs?.map((seoFaq) => ({
            id: seoFaq,
          }))

          const data = {
            ...this.form,
            seoTexts,
            seoFaqs,
          }

          const result = await delivery.ContentServiceCore.PagesActions.update(
            'documents',
            'addwine',
            data,
          )
          loading.close()

          if (result.error) return

          this.showNotification('Страница успешно отредактирована', 'success')

          this.getContent()
        }
      })
    },
  },
}
</script>

<style lang="scss" module>
.contacts {
  padding: 0 1rem;
  .wrapper {
    @include stickyWrapper;
    .button {
      margin-left: 1rem;
    }
  }
  .icon {
    width: 2.5rem;
    height: 2.5rem;
  }

  .form {
    h2 {
      text-align: center;
      margin-bottom: 3rem;
    }
    h3 {
      margin-bottom: 1rem;
    }
    .orderInfo {
      .seoTexts {
        width: 100%;
      }
    }

    .seo {
      & > div > label {
        text-align: left;
      }
    }
  }
}
</style>
